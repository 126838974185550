import styled from 'styled-components';

export const Container = styled.div`
	color: #e5c8a2;
	font-size: 16px;
	font-weight: 700;
	font-family: 'Roboto-Condensed-Regular';

	a {
		text-decoration: underline;

		&:hover {
			opacity: 0.8;
		}
	}

	@media (max-width: 1100px) {
		width: 100%;
		font-size: 12px;
	}
`;

export const NoLocation = styled.a`
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	justify-content: center;
	text-decoration: unset !important;

	&:hover {
		opacity: 0.7;
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;

	@media (max-width: 768px) {
		width: 100%;
		padding: 0 16px;
	}
`;

export const Name = styled.div`
	display: flex;
	align-items: center;
	padding-right: 16px;

	& > *:not(:first-child) {
		margin: 0 0 0 8px;
	}

	@media (max-width: 768px) {
		flex: 1;
	}
`;

export const NameLabel = styled.span`
	color: #e5c8a2;
	white-space: nowrap;

	@media (max-width: 1300px) {
		display: none;
	}

	@media (max-width: 768px) {
		display: flex;
		color: #2c2c2b;
	}

	@media (max-width: 390px) {
		display: none;
	}
`;

export const NameValue = styled.a`
	font-weight: bold;
	text-transform: uppercase;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (min-width: 769px) {
		max-width: 150px;
	}

	@media (max-width: 768px) {
		font-size: 12px;
		max-width: 160px;
	}

	@media (max-width: 420px) {
		max-width: 140px;
	}

	@media (max-width: 390px) {
		max-width: 120px;
	}

	@media (max-width: 350px) {
		max-width: 100px;
	}
`;

export const Icon = styled.div`
	width: 16px;
	height: 16px;
`;

export const Chevron = styled.div`
	margin: 0 8px;
	width: 16px;

	@media (max-width: 768px) {
		display: none;
	}
`;

export const Open = styled.div``;

export const TableWait = styled.span`
	margin: 0 0 0 12px;
	position: relative;
	white-space: nowrap;
	&::before {
		content: '/';
		position: absolute;
		left: -9px;
	}
`;

export const Seating = styled.span`
	@media (max-width: 768px) {
		display: none;
	}
`;

export const LocationIcon = styled.div`
	width: 15px;
	height: 19px;

	@media (max-width: 768px) {
		width: 10px;
		height: 13px;
	}
`;
