import { AppState } from '@maverick/cms';
import { Location } from './Location';
import { connect, ConnectedProps } from 'react-redux';

const mapStateToProps = (state: AppState) => ({
	seatingData: state.seating.seatingData,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type LocationReduxProps = ConnectedProps<typeof connector>;
const LocationConnected = connector(Location);
export { LocationConnected as Location };
